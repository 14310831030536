var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getAuthMessage.message ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-inline-alert', {
    attrs: {
      "message": _vm.getAuthMessage.message,
      "message-type": _vm.getAuthMessage.type
    }
  })], 1) : _vm._e(), _c('v-form', {
    staticClass: "mt-15",
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:27|min:3',
      expression: "'required|max:27|min:3'"
    }],
    attrs: {
      "label": "Password",
      "placeholder": "Enter the new password",
      "filled": "",
      "counter": "27",
      "type": "password"
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }, 'v-text-field', _vm.veeValidate('Password1', 'Password1'), false)), _c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|max:27|min:3',
      expression: "'required|max:27|min:3'"
    }],
    attrs: {
      "label": "Confirm password",
      "placeholder": "Confirm your password",
      "filled": "",
      "counter": "27",
      "type": "password",
      "error-messages": _vm.form.password === _vm.form.confirm_password ? null : 'Password does not match'
    },
    model: {
      value: _vm.form.confirm_password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "confirm_password", $$v);
      },
      expression: "form.confirm_password"
    }
  }, 'v-text-field', _vm.veeValidate('Password2', 'Password2'), false)), _c('v-btn', {
    staticClass: "main primary mx-auto",
    attrs: {
      "type": "submit",
      "disabled": _vm.errors.any() || _vm.busy || !_vm.form.confirm_password || !_vm.form.password || _vm.form.password !== _vm.form.confirm_password,
      "loading": _vm.busy
    }
  }, [_vm._v(" submit ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }